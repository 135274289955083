import React from "react";
import { graphql } from "gatsby";
import Blog from "../components/blog";

export const query = graphql`
  query($formatString: String!) {
    allPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        tags {
          name
          slug
        }
      }
    }
  }
`;

type Props = {
  data: {
    allPost: any;
    [key: string]: string;
  };
};

export default function MinimalBlogCoreBlog({ data }: Props) {
  const { allPost } = data;

  return <Blog posts={allPost.nodes} />;
}
